.context-card-box {
  box-shadow: 0px 2px 4px rgba(34, 136, 238, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  background-color: #fdfdfd;
}

.context-card-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #2288ee;
}

.modal-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #f5f6f7;
  color: #6e7175;
  border: 1px solid #d1d1d1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
