.title {
    font-size: 5rem;
}

.subtitle {
    font-size: 1.5rem
}

.highlight {
    color: var(--main-button-color)
}