.fill_blank_area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  font-family: "ClanOT-NarrowNews";
}

.text_area {
  font-size: 20px;
  border-bottom: 1px;
}

.check_button {
  font-size: 20px;
  padding: 15px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
}
