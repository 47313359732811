.task-option {
  box-shadow: 0px 2px 4px rgba(34, 136, 238, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  background-color: #fdfdfd;
}

.task-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #2288ee;
}
