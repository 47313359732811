.modal-style {
  position: fixed;
  width: 100vw;
  height: 100vh;
  /* transform: translate(-50%, -50%); */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: 9999;
  /* overflow: auto; */
  top: 0;
  left: 0;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  color: var(--primary-button-background);
  margin: 1rem 1.25rem;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 31.125rem;
  /* height: 40.125rem; */
  border: 2px solid #000;
  box-shadow: 0px 10px 17px rgba(0, 51, 102, 0.1);
  top: 20%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1.25rem;
}

.modal-content-mobile {
  position: relative;
  background-color: white;
  /* width: 31.125rem; */
  /* height: 40.125rem; */
  border: 2px solid #000;
  box-shadow: 0px 10px 17px rgba(0, 51, 102, 0.1);
  top: 20%;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  border-radius: 1.25rem;
}

.modal-footer {
  /* margin-top: 2.5rem;
    margin-bottom: 2.5rem; */
  margin: 2.5rem 0rem;
}

.modal-body {
  margin-top: 2.5rem;
}
