.task-option {
  box-shadow: 0px 2px 4px rgba(34, 136, 238, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
}

.task-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #2288ee;
}

.title-today {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}

.task-heading-container {
  border-radius: 10px;
  background: #e9f3fd;
  /* max-width: fit-content; */

  padding: 10px;
  /* width: 80px;
  height: 50px; */
}

.task-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #2288ee;
}

.column-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}

.to-do-list {
  background: #fafafb;
  border-radius: 10px;
}

.point-container {
  background: #e9f3fd;
  border-radius: 10px;
}

.point-text {
  /* font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 18px; */
  color: #2288ee;
}

.column {
  box-shadow: 0px 2px 4px rgba(34, 136, 238, 0.1);
}

.welcomeModalBody {
  display: flex;
  flex-direction: column;
  margin: 22px 20px;
}

.dashboardContainer {
  display: flex;
}

.dashboardColumns {
  display: flex;
  flex-direction: column;
}

.cornerDashBoardColumn {
  flex: 0.5
}

.centerDashBoardColumn {
  flex: 2.15;
}

.chartsColumn {
  flex: 1.15;
}