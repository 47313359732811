.text-box-primary {
  box-sizing: border-box;
  border-radius: 0.5rem;
  width: 21.875rem;
  height: 3rem;
  padding: 0.625rem;
  background-color: var(--form-background);
}

.text-box-default {
  border: 0.0625rem solid var(--form-border);
}

.text-box-primary:hover {
  border: 0.0625rem solid var(--form-border-on-hover);
}

.text-box-error {
  border: 0.0625rem solid var(--error);
}

.text-box-errorText {
  color: var(--error);
}

.text-box-primary:disabled {
  background: var(--form-disabled-background);
  color: var(--form-disabled-text);
}
